import { call, put, takeLatest } from 'redux-saga/effects';
import ApiManager from 'network/ApiManager';
import StatusCode from 'network/StatusCode';
import { getEcgTestSucceed } from './testResultDuck';
// import { getReportSelectedRequested } from './reportDuck';

// Actions
// Create
const CREATE_ECG_TEST_REQUESTED =
  'memo-web/ecg-tests/CREATE_ECG_TEST_REQUESTED';
const CREATE_ECG_TEST_SUCCEED = 'memo-web/ecg-tests/CREATE_ECG_TEST_SUCCEED';
const CREATE_ECG_TEST_FAILED = 'memo-web/ecg-tests/CREATE_ECG_TEST_FAILED';
// Init. Fetch Data
const INIT_ECG_TESTS = 'memo-web/ecg-tests/INIT_ECG_TESTS';
// Fetch (Multiple)
const FETCH_ECG_TESTS_REQUESTED =
  'memo-web/ecg-tests/FETCH_ECG_TESTS_REQUESTED';
const FETCH_ECG_TESTS_SUCCEED = 'memo-web/ecg-tests/FETCH_ECG_TESTS_SUCCEED';
const FETCH_ECG_TESTS_FAILED = 'memo-web/ecg-tests/FETCH_ECG_TESTS_FAILED';
// Fetch (Single)
const FETCH_ECG_TEST_REQUESTED = 'memo-web/ecg-tests/FETCH_ECG_TEST_REQUESTED';
const FETCH_ECG_TEST_SUCCEED = 'memo-web/ecg-tests/FETCH_ECG_TEST_SUCCEED';
const FETCH_ECG_TEST_FAILED = 'memo-web/ecg-tests/FETCH_ECG_TEST_FAILED';
// Search
const SEARCH_ECG_TESTS_REQUESTED =
  'memo-web/ecg-tests/SEARCH_ECG_TESTS_REQUESTED';
const SEARCH_ECG_TESTS_SUCCEED = 'memo-web/ecg-tests/SEARCH_ECG_TESTS_SUCCEED';
const SEARCH_ECG_TESTS_FAILED = 'memo-web/ecg-tests/SEARCH_ECG_TESTS_FAILED';
// Patch
const PATCH_ECG_TEST_REQUESTED = 'memo-web/ecg-tests/PATCH_ECG_TEST_REQUESTED';
const PATCH_ECG_TEST_SUCCEED = 'memo-web/ecg-tests/PATCH_ECG_TEST_SUCCEED';
const PATCH_ECG_TEST_FAILED = 'memo-web/ecg-tests/PATCH_ECG_TEST_FAILED';
// Revert ECG Test Status CUSTOMER_CONFIRMED to CLOUD_CONFIRMED
const REVERT_STATUS_REQUESTED = 'memo-web/ecg-tests/REVERT_STATUS_REQUESTED';
const REVERT_STATUS_SUCCEED = 'memo-web/ecg-tests/REVERT_STATUS_SUCCEED';
const REVERT_STATUS_FAILED = 'memo-web/ecg-tests/REVERT_STATUS_FAILED';
// Delete
const DELETE_ECG_TEST_REQUESTED =
  'memo-web/ecg-tests/DELETE_ECG_TEST_REQUESTED';
const DELETE_ECG_TEST_SUCCEED = 'memo-web/ecg-tests/DELETE_ECG_TEST_SUCCEED';
const DELETE_ECG_TEST_FAILED = 'memo-web/ecg-tests/DELETE_ECG_TEST_FAILED';
// Return Device
const ECG_TEST_RETURN_DEVICE_REQUESTED =
  'memo-web/ecg-tests/ECG_TEST_RETURN_DEVICE_REQUESTED';
const ECG_TEST_RETURN_DEVICE_SUCCEED =
  'memo-web/ecg-tests/ECG_TEST_RETURN_DEVICE_SUCCEED';
const ECG_TEST_RETURN_DEVICE_FAILED =
  'memo-web/ecg-tests/ECG_TEST_RETURN_DEVICE_FAILED';
// Confirm Review
const ECG_TEST_CONFIRM_REVIEW_REQUESTED =
  'memo-web/ecg-tests/ECG_TEST_CONFIRM_REVIEW_REQUESTED';
const ECG_TEST_CONFIRM_REVIEW_SUCCEED =
  'memo-web/ecg-tests/ECG_TEST_CONFIRM_REVIEW_SUCCEED';
const ECG_TEST_CONFIRM_REVIEW_FAILED =
  'memo-web/ecg-tests/ECG_TEST_CONFIRM_REVIEW_FAILED';
// Edit Done Review
const ECG_TEST_EDIT_DONE_REVIEW_REQUESTED =
  'memo-web/ecgTest/ECG_TEST_EDIT_DONE_REVIEW_REQUESTED';
const ECG_TEST_EDIT_DONE_REVIEW_SUCCEED =
  'memo-web/ecgTest/ECG_TEST_EDIT_DONE_REVIEW_SUCCEED';
const ECG_TEST_EDIT_DONE_REVIEW_FAILED =
  'memo-web/ecgTest/ECG_TEST_EDIT_DONE_REVIEW_FAILED';
// get validReport latest value from ECG Test API
const GET_VALID_REPORT_REQUESTED =
  'memo-web/ecg-tests/GET_VALID_REPORT_REQUESTED';
const GET_VALID_REPORT_SUCCEED = 'memo-web/ecg-tests/GET_VALID_REPORT_SUCCEED';
const GET_VALID_REPORT_FAILED = 'memo-web/ecg-tests/GET_VALID_REPORT_FAILED';
// Update Local State
const UPDATE_ECG_TEST_INFO = 'memo-web/ecg-tests/UPDATE_ECG_TEST_INFO';

// Reducer
const initialState = {
  create: {
    pending: false,
    data: null,
    error: null,
  },
  fetch: {
    fetchType: '',
    pending: false,
    firstPage: 1,
    currentPage: 1,
    lastPage: null,
    totalCount: null,
    data: [],
    error: null,
  },
  fetchItem: {
    pending: false,
    data: null,
    error: null,
  },
  patch: {
    pending: false,
    data: null,
    error: null,
  },
  revertStatus: {
    pending: false,
    error: null,
  },
  delete: {
    pending: false,
    data: null,
    error: null,
  },
  returnDevice: {
    pending: false,
    data: null,
    error: null,
  },
  confirm: {
    pending: false,
    data: null,
    error: null,
  },
  editDone: {
    pending: false,
    data: null,
    error: null,
  },
  validReport: {
    pending: false,
    data: false,
    error: null,
  },
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    // Create
    case CREATE_ECG_TEST_REQUESTED:
      return {
        ...state,
        create: {
          ...state.create,
          pending: true,
          error: null,
        },
      };
    case CREATE_ECG_TEST_SUCCEED:
      return {
        ...state,
        create: {
          ...state.create,
          pending: false,
          data: action.data,
        },
      };
    case CREATE_ECG_TEST_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          pending: false,
          error: action.error,
        },
      };
    case INIT_ECG_TESTS:
      return {
        ...state,
        fetch: {
          fetchType: '',
          pending: false,
          firstPage: 1,
          currentPage: 1,
          lastPage: null,
          totalCount: null,
          data: [],
          error: null,
        },
      };
    // Fetch (Multiple)
    case FETCH_ECG_TESTS_REQUESTED:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          ...(action.isRefresh && {
            firstPage: 1,
            currentPage: 1,
            lastPage: null,
            totalCount: null,
          }),
          pending: true,
          error: null,
        },
      };
    case FETCH_ECG_TESTS_SUCCEED:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          pending: false,
          fetchType: 'fetchMultiple',
          currentPage: action.currentPage,
          lastPage: action.lastPage,
          totalCount: action.totalCount,
          data: action.data,
        },
      };
    case FETCH_ECG_TESTS_FAILED:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          pending: false,
          error: action.error,
        },
      };
    // Fetch (Single)
    case FETCH_ECG_TEST_REQUESTED:
      return {
        ...state,
        fetchItem: {
          ...state.fetchItem,
          pending: true,
          error: null,
        },
      };
    case FETCH_ECG_TEST_SUCCEED:
      return {
        ...state,
        fetchItem: {
          ...state.fetchItem,
          pending: false,
          data: action.data,
        },
      };
    case FETCH_ECG_TEST_FAILED:
      return {
        ...state,
        fetchItem: {
          ...state.fetchItem,
          pending: false,
          error: action.error,
        },
      };
    // Search
    case SEARCH_ECG_TESTS_REQUESTED:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          ...(action.isRefresh && {
            firstPage: 1,
            currentPage: 1,
            lastPage: null,
            totalCount: null,
          }),
          pending: true,
          error: null,
        },
      };
    case SEARCH_ECG_TESTS_SUCCEED:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          pending: false,
          fetchType: 'search',
          currentPage: action.currentPage,
          lastPage: action.lastPage,
          totalCount: action.totalCount,
          data: action.data,
        },
      };
    case SEARCH_ECG_TESTS_FAILED:
      return {
        ...state,
        fetch: {
          ...state.fetch,
          pending: false,
          error: action.error,
        },
      };
    // Patch
    case PATCH_ECG_TEST_REQUESTED:
      return {
        ...state,
        patch: {
          ...state.patch,
          pending: true,
          error: null,
        },
      };
    case PATCH_ECG_TEST_SUCCEED:
      return {
        ...state,
        fetchItem: {
          ...state.fetchItem,
          data: {
            ...state.fetchItem.data,
            ...action.data,
          },
        },
        patch: {
          ...state.patch,
          pending: false,
          // data: action.data,
        },
      };
    case PATCH_ECG_TEST_FAILED:
      return {
        ...state,
        patch: {
          ...state.patch,
          pending: false,
          error: action.error,
        },
      };
    // Revert ECG Test Status CUSTOMER_CONFIRMED to CLOUD_CONFIRMED
    case REVERT_STATUS_REQUESTED:
      return {
        ...state,
        revertStatus: {
          pending: true,
          error: null,
        },
      };
    case REVERT_STATUS_SUCCEED:
      return {
        ...state,
        fetchItem: {
          ...state.fetchItem,
          data: action.data,
        },
        revertStatus: {
          ...state.revertStatus,
          pending: false,
        },
      };
    case REVERT_STATUS_FAILED:
      return {
        ...state,
        revertStatus: {
          ...state.revertStatus,
          pending: false,
          error: action.error,
        },
      };
    // Delete
    case DELETE_ECG_TEST_REQUESTED:
      return {
        ...state,
        delete: {
          ...state.delete,
          pending: true,
          error: null,
        },
      };
    case DELETE_ECG_TEST_SUCCEED:
      return {
        ...state,
        delete: {
          ...state.delete,
          pending: false,
          data: action.data,
        },
      };
    case DELETE_ECG_TEST_FAILED:
      return {
        ...state,
        delete: {
          ...state.delete,
          pending: false,
          error: action.error,
        },
      };
    // Return Device
    case ECG_TEST_RETURN_DEVICE_REQUESTED:
      return {
        ...state,
        returnDevice: {
          ...state.returnDevice,
          pending: true,
          error: null,
        },
      };
    case ECG_TEST_RETURN_DEVICE_SUCCEED:
      return {
        ...state,
        returnDevice: {
          ...state.returnDevice,
          pending: false,
          data: action.data,
        },
      };
    case ECG_TEST_RETURN_DEVICE_FAILED:
      return {
        ...state,
        returnDevice: {
          ...state.returnDevice,
          pending: false,
          error: action.error,
        },
      };
    // Confirm Review
    case ECG_TEST_CONFIRM_REVIEW_REQUESTED:
      return {
        ...state,
        confirm: {
          ...state.confirm,
          pending: true,
          error: null,
        },
      };
    case ECG_TEST_CONFIRM_REVIEW_SUCCEED:
      return {
        ...state,
        confirm: {
          ...state.confirm,
          pending: false,
          data: action.data,
        },
      };
    case ECG_TEST_CONFIRM_REVIEW_FAILED:
      return {
        ...state,
        confirm: {
          ...state.confirm,
          pending: false,
          error: action.error,
        },
      };
    // Edit Done Review
    case ECG_TEST_EDIT_DONE_REVIEW_REQUESTED:
      return {
        ...state,
        editDone: {
          pending: true,
          error: null,
        },
      };
    case ECG_TEST_EDIT_DONE_REVIEW_SUCCEED:
      return {
        ...state,
        editDone: {
          pending: false,
          data: action.data,
        },
      };
    case ECG_TEST_EDIT_DONE_REVIEW_FAILED:
      return {
        ...state,
        editDone: {
          pending: false,
          error: action.error,
        },
      };
    //
    case UPDATE_ECG_TEST_INFO:
      return {
        ...state,
        fetchItem: {
          ...state.fetchItem,
          data: {
            ...state.fetchItem.data,
            [action.key]: action.value,
          },
        },
      };
    // get validReport latest value from ECG Test API
    case GET_VALID_REPORT_REQUESTED:
      return {
        ...state,
        validReport: {
          ...state.validReport,
          pending: true,
          data: false,
          error: null,
        },
      };
    case GET_VALID_REPORT_SUCCEED:
      return {
        ...state,
        validReport: {
          ...state.validReport,
          pending: false,
          data: action.data.summary.validReport,
        },
      };
    case GET_VALID_REPORT_FAILED:
      return {
        ...state,
        validReport: {
          ...state.validReport,
          pending: false,
          data: false,
          error: action.error,
        },
      };

    default:
      return state;
  }
}

// Action Creators
// Create
export function createEcgTestRequested(
  patientName,
  patientNumber,
  patientSex,
  patientPhoneNumber,
  patientBirth,
  testDuration,
  referredBy
) {
  return {
    type: CREATE_ECG_TEST_REQUESTED,
    patientName,
    patientNumber,
    patientSex,
    patientPhoneNumber,
    patientBirth,
    testDuration,
    referredBy,
  };
}
function createEcgTestSucceed(data) {
  return {
    type: CREATE_ECG_TEST_SUCCEED,
    data: data,
  };
}
function createEcgTestFailed(error) {
  return {
    type: CREATE_ECG_TEST_FAILED,
    error: error,
  };
}
// Init. Fetch Data
export function initEcgTests() {
  return {
    type: INIT_ECG_TESTS,
  };
}
// Fetch (Multiple)
export function fetchEcgTestsRequested(
  statusArray,
  page,
  pageSize,
  orderingList,
  searchKeyword,
  isRefresh
) {
  return {
    type: FETCH_ECG_TESTS_REQUESTED,
    statusArray: statusArray,
    page: page,
    pageSize: pageSize,
    orderingList: orderingList,
    searchKeyword,
    isRefresh: isRefresh,
  };
}
export function fetchEcgTestsSucceed(currentPage, lastPage, totalCount, data) {
  return {
    type: FETCH_ECG_TESTS_SUCCEED,
    currentPage: currentPage,
    lastPage: lastPage,
    totalCount: totalCount,
    data: data,
  };
}
export function fetchEcgTestsFailed(error) {
  return { type: FETCH_ECG_TESTS_FAILED, error: error };
}
// Fetch (Single)
export function fetchEcgTestRequested(ecgTestId, callback) {
  return {
    type: FETCH_ECG_TEST_REQUESTED,
    ecgTestId: ecgTestId,
    callback,
  };
}
function fetchEcgTestSucceed(data) {
  return {
    type: FETCH_ECG_TEST_SUCCEED,
    data: data,
  };
}
function fetchEcgTestFailed(error) {
  return { type: FETCH_ECG_TEST_FAILED, error: error };
}

// Search
export function searchEcgTestsRequested(
  statusArray,
  orderingList,
  page,
  pageSize,
  keyword
) {
  return {
    type: SEARCH_ECG_TESTS_REQUESTED,
    statusArray: statusArray,
    orderingList: orderingList,
    page: page,
    pageSize: pageSize,
    keyword: keyword,
  };
}
export function searchEcgTestSucceed(currentPage, lastPage, totalCount, data) {
  return {
    type: SEARCH_ECG_TESTS_SUCCEED,
    currentPage: currentPage,
    lastPage: lastPage,
    totalCount: totalCount,
    data: data,
  };
}
export function searchEcgTestFailed(error) {
  return { type: SEARCH_ECG_TESTS_FAILED, error: error };
}
// Patch
export function patchEcgTestRequested(ecgTestId, form, callback) {
  return {
    type: PATCH_ECG_TEST_REQUESTED,
    ecgTestId,
    form,
    callback,
  };
}
function patchEcgTestSucceed(data) {
  return {
    type: PATCH_ECG_TEST_SUCCEED,
    data: data,
  };
}
function patchEcgTestFailed(error) {
  return {
    type: PATCH_ECG_TEST_FAILED,
    error: error,
  };
}
// Revert ECG Test Status CUSTOMER_CONFIRMED to CLOUD_CONFIRMED
export function revertStatusRequested(ecgTestId, callback) {
  return {
    type: REVERT_STATUS_REQUESTED,
    ecgTestId,
    callback,
  };
}
function revertStatusSucceed(data) {
  return {
    type: REVERT_STATUS_SUCCEED,
    data: data,
  };
}
function revertStatusFailed(error) {
  return {
    type: REVERT_STATUS_FAILED,
    error: error,
  };
}
// Delete
export function deleteEcgTestRequested(ecgTestId) {
  return {
    type: DELETE_ECG_TEST_REQUESTED,
    ecgTestId: ecgTestId,
  };
}
export function deleteEcgTestSucceed(data) {
  return {
    type: DELETE_ECG_TEST_SUCCEED,
    data: data,
  };
}
export function deleteEcgTestFailed(error) {
  return {
    type: DELETE_ECG_TEST_FAILED,
    error: error,
  };
}
// Return Device
export function ecgTestReturnDeviceRequested(ecgTestId) {
  return {
    type: ECG_TEST_RETURN_DEVICE_REQUESTED,
    ecgTestId: ecgTestId,
  };
}
export function ecgTestReturnDeviceSucceed(data) {
  return {
    type: ECG_TEST_RETURN_DEVICE_SUCCEED,
    data: data,
  };
}
export function ecgTestReturnDeviceFailed(error) {
  return {
    type: ECG_TEST_RETURN_DEVICE_FAILED,
    error: error,
  };
}
// Confirm Review
export function confirmEcgTestReviewRequested(
  ecgTestId,
  reGenerateReport,
  callback
) {
  return {
    type: ECG_TEST_CONFIRM_REVIEW_REQUESTED,
    ecgTestId: ecgTestId,
    reGenerateReport,
    callback,
  };
}
function confirmEcgTestReviewSucceed(data) {
  return {
    type: ECG_TEST_CONFIRM_REVIEW_SUCCEED,
    data: data,
  };
}
function confirmEcgTestReviewFailed(error) {
  return {
    type: ECG_TEST_CONFIRM_REVIEW_FAILED,
    error: error,
  };
}
// Edit Done Review
export function editDoneEcgTestReviewRequested(ecgTestId) {
  return { type: ECG_TEST_EDIT_DONE_REVIEW_REQUESTED, ecgTestId };
}
function editDoneEcgTestReviewSucceed(data) {
  return { type: ECG_TEST_EDIT_DONE_REVIEW_SUCCEED, data };
}
function editDoneEcgTestReviewFailed(error) {
  return { type: ECG_TEST_EDIT_DONE_REVIEW_FAILED, error };
}
// Update Local State
export function updateEcgTestInfo(key, value) {
  return {
    type: UPDATE_ECG_TEST_INFO,
    key,
    value,
  };
}
// get validReport latest value from ECG Test API
export function getValidReportRequested(ecgTestId) {
  return {
    type: GET_VALID_REPORT_REQUESTED,
    ecgTestId: ecgTestId,
  };
}
function getValidReportSucceed(data) {
  return {
    type: GET_VALID_REPORT_SUCCEED,
    data: data,
  };
}
function getValidReportFailed(error) {
  return { type: GET_VALID_REPORT_FAILED, error: error };
}

// Sagas
function* createEcgTest(action) {
  try {
    const { status, data } = yield call(
      ApiManager.createEcgTest,
      action.patientName,
      action.patientNumber,
      action.patientSex,
      action.patientPhoneNumber,
      action.patientBirth,
      action.testDuration,
      action.referredBy,
      action.referredBy
    );

    yield put(createEcgTestSucceed(data));
  } catch (error) {
    yield put(createEcgTestFailed(error));
  }
}

function* readEcgTests(action) {
  try {
    const { status, data } = yield call(
      ApiManager.readEcgTests,
      action.statusArray,
      action.page,
      action.pageSize,
      action.orderingList,
      action.searchKeyword
    );

    const { count, next, previous, results } = data;
    const lastPage = Math.ceil(count / action.pageSize);
    yield put(fetchEcgTestsSucceed(action.page, lastPage, count, results));
  } catch (error) {
    yield put(fetchEcgTestsFailed(error));
  }
}

function* readEcgTest(action) {
  try {
    const { data } = yield call(
      ApiManager.readEcgTest,
      { ecgTestId: action.ecgTestId },
      action.callback
    );

    yield put(fetchEcgTestSucceed(data.result));
    // yield put(getReportSelectedRequested(data.result.latestRid));
  } catch (error) {
    yield put(fetchEcgTestFailed(error));
  }
}

function* searchEcgTests(action) {
  try {
    const { status, data } = yield call(
      ApiManager.searchEcgTests,
      action.statusArray,
      action.orderingList,
      action.page,
      action.pageSize,
      action.keyword
    );

    const { count, next, previous, results } = data;
    const lastPage = Math.ceil(count / action.pageSize);
    yield put(searchEcgTestSucceed(action.page, lastPage, count, results));
  } catch (error) {
    yield put(searchEcgTestFailed(error));
  }
}

function* patchEcgTest(action) {
  try {
    const { data } = yield call(
      ApiManager.patchEcgTest,
      action.ecgTestId,
      action.form,
      action.callback
    );

    yield put(patchEcgTestSucceed(data.result));
  } catch (error) {
    yield put(patchEcgTestFailed(error));
  }
}

function* revertStatus(action) {
  try {
    const { data } = yield call(
      ApiManager.patchRevertStatus,
      action.ecgTestId,
      action.callback
    );

    yield put(getEcgTestSucceed(data.result));
    yield put(revertStatusSucceed(data.result));
  } catch (error) {
    yield put(revertStatusFailed(error));
  }
}

function* deleteEcgTest(action) {
  try {
    const { status, data } = yield call(
      ApiManager.deleteEcgTest,
      action.ecgTestId
    );

    yield put(deleteEcgTestSucceed(data));
  } catch (error) {
    yield put(deleteEcgTestFailed(error));
  }
}

function* ecgTestReturnDevice(action) {
  try {
    const { status, data } = yield call(
      ApiManager.ecgTestReturnDevice,
      action.ecgTestId
    );

    yield put(ecgTestReturnDeviceSucceed(data));
  } catch (error) {
    yield put(ecgTestReturnDeviceFailed(error));
  }
}

function* ecgTestConfirmReview(action) {
  try {
    const { data } = yield call(
      ApiManager.ecgTestConfirmReview,
      action.ecgTestId
    );

    yield put(confirmEcgTestReviewSucceed(data));
  } catch (error) {
    yield put(confirmEcgTestReviewFailed(error));
  }
}

function* ecgTestEditDoneReview(action) {
  try {
    const { data: editDoneData } = yield call(
      ApiManager.ecgTestEditDoneReview,
      action.ecgTestId
    );
    const { result } = editDoneData;

    yield put(editDoneEcgTestReviewSucceed(result));
  } catch (error) {
    yield put(editDoneEcgTestReviewFailed(error));
  }
}

function* getValidReport(action) {
  try {
    const { data } = yield call(ApiManager.readEcgTest, {
      ecgTestId: action.ecgTestId,
    });

    yield put(getValidReportSucceed(data.result));
  } catch (error) {
    yield put(getValidReportFailed(error));
  }
}

export function* saga() {
  yield takeLatest(CREATE_ECG_TEST_REQUESTED, createEcgTest);
  yield takeLatest(FETCH_ECG_TESTS_REQUESTED, readEcgTests);
  yield takeLatest(FETCH_ECG_TEST_REQUESTED, readEcgTest);
  yield takeLatest(SEARCH_ECG_TESTS_REQUESTED, searchEcgTests);
  yield takeLatest(PATCH_ECG_TEST_REQUESTED, patchEcgTest);
  yield takeLatest(REVERT_STATUS_REQUESTED, revertStatus);
  yield takeLatest(DELETE_ECG_TEST_REQUESTED, deleteEcgTest);
  yield takeLatest(ECG_TEST_RETURN_DEVICE_REQUESTED, ecgTestReturnDevice);
  yield takeLatest(ECG_TEST_CONFIRM_REVIEW_REQUESTED, ecgTestConfirmReview);
  yield takeLatest(ECG_TEST_EDIT_DONE_REVIEW_REQUESTED, ecgTestEditDoneReview);
  yield takeLatest(GET_VALID_REPORT_REQUESTED, getValidReport);
}
